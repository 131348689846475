@tailwind base;
@tailwind components;
@tailwind utilities;

/* coolvetica fonts */
@font-face {
  font-family: 'Coolvetica';
  src: url('../public/fonts/coolvetica/coolvetica\ rg.ttf') format('woff2'),
       url('../public/fonts/coolvetica/coolvetica\ rg\ it.ttf') format('woff2');


  font-weight: normal;
  font-style: normal;
}

/* DM Sans fonts */
@font-face {
  font-family: 'DM Sans';
  src: url('../public/fonts/DMSans.ttf') format('woff2');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans Sans-serif';
  src: url('../public/fonts/DMSans-Italic-VariableFont_opsz\,wght.ttf') format('woff');

  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src: url('../public/fonts/SharpSans.otf') ;

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SharpSans';
  src: url('../public/fonts/Sharp\ Sans\ Semibold.otf') ;

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SharpSans';
  src: url('../public/fonts/SharpSansBold.otf') ;

  font-weight: 800;
  font-style: normal;
}

/* Add more @font-face rules for other font weights and styles as needed */

@font-face {
  font-family: sharp-sans-bold;
  src: url('../public/fonts/SharpSansBold.otf');
}
.sharp-sans-bold{
  font-family: 'sharp-sans-bold';
}

@font-face {
  font-family: sharp-sans;
  src: url('../public/fonts/Sharp-Sans.otf');
}
.sharp-sans{
  font-family: 'sharp-sans';
}

@font-face {
  font-family: urbanist-font;
  src: url('../public/fonts/Urbanist-Medium.ttf');
}
.urbanist-font{
  font-family: 'urbanist-font';
}

@font-face {
  font-family: axiforma;
  src: url('../public/fonts/Axiforma_Regular.ttf');
}
.axiforma{
  font-family: 'axiforma';
}

body {
  font-family: 'DM Sans', sans-serif;
}



/* Nav Section */
.App {
    background: #000000;
    padding: 50px 100px;
    overflow-x: hidden;
}
.request-nav-button {
  cursor: pointer;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 7.698px;
  background: #6BFDCB;
  box-shadow: 0px 0.962px 1.925px 0px rgba(16, 24, 40, 0.05);
  text-decoration: none;
  color: #000;
}

#pointer{
  color: #f1ffc9;
}
.rq-now-btn {
  display: none;
}

.rq-btn-link {
  text-decoration: none;
}


@media (max-width: 400px) {
  .price-session-container {
    margin-top: 6em;
    text-align: center;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  .price-session-container {
    margin-top: 3em;
  }
}

@media (max-width: 520px) {
  .App {
    padding: 20px;
  }

  nav {
    display: flex;
    flex-direction: column;
  }

  nav .logo {
    margin: 2em auto;
    gap: 10px;
  }

  .cta-up-button {
    width: 100%;
  }
}

@media (min-width: 520px) and (max-width: 640px) {
  .cta-up-button {
    width: 100%;
  }

  .invisible-email-input {
    width: 100%;
    margin-top: 2em;
  }

  .logo {
    margin: 1em auto;
  }
}

@media (max-width: 768px) {
  /* .App {
    padding: 50px;
  } */

  nav ul {
    gap: 10px;
    margin: 0 1em;
  }

  .cta-up-button {
    margin: 2em auto;
  }

  .invisible-email-input {
    width: 100%;
    margin-top: 2em;
  }
}

/* Hero Section */

.app-is-live-div {
  background: var(--Success-50, #ECFDF3);
}

.badge-img {
  color: #fff;
  margin-right: 6px;
  margin-left: 1px;
  display: flex;
  align-items: flex-start;
}

.app-is-live-text {
  color: var(--Success-700, #027A48);
  font-family: Inter;
  font-size: 11.548px;
  font-style: normal;
  font-weight: 500;
  }


.hero-small-text {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 19.246px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.869px; /* 150% */

}

.request-now-button {
  cursor: pointer;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 7.698px;
  border: 0.962px solid #6BFDCB;
  background: #6BFDCB;
  box-shadow: 0px 0.962px 1.925px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 9.623px 17.321px;
  justify-content: center;
  align-items: center;
  gap: 7.698px;
  text-decoration: none;
  color: #000;
}

.request-now-button:hover{
  border: none;
  outline: none;
  background-color: none;
}

.contact-us-button {
  display: flex;
  padding: 9.623px 17.321px;
  justify-content: center;
  align-items: center;
  gap: 7.698px;
  border-radius: 7.698px;
  border: 0.962px solid #3F3F3F;
  box-shadow: 0px 0.962px 1.925px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
}

.contact-us-button:hover {
  background-color: #fff;
  color: #000;
}

.error-message {
  color: #ff0000;
}

.btn-icon {
    width: 2em;
    height: 1em;
}

.icon-article {
    display: flex;
    align-items: center;
    margin-top: 1.5em;
  }

  /* .flex-1 {
    height: 600px;
    background: green;
  } */

  /* generate a keyfram that moves from top-left to top-right, then top-right to bottom-right, then from bottom-right to bottom-left then from bottom-left back to the initial position which is top-left,
   for each change in position turn the object 90 degree
  */



  @keyframes default_moveAndRotate {
    0% {
      top: -8%;
      left: -10%;
      transform: rotate(0deg);
    } 
    20% { 
      top: -8%;
      left: 90%;
      transform: rotate(0deg);
    }
    25% {
      top: 0%;
      left: 90%;
      transform: rotate(90deg);
    }
    45% {
      top: 90%;
      left: 90%;
      transform: rotate(90deg);
    }
    50% {
      top: 96%;
      left: 78%;
      transform: rotate(180deg);
    }
    70% {
      top: 96%;
      left: -10%;
      transform: rotate(180deg);
    }
    75% {
      top: 90%;
      left: -15%;
      transform: rotate(270deg);
    }
    95% {
      top: -8%;
      left: -15%;
      transform: rotate(270deg);
    }
    100% {
      top: -8%;
      left: -10%;
      transform: rotate(360deg);
    } 
  }

  @keyframes sm_moveAndRotate {
    0% {
      top: -13%;
      left: -10%;
      transform: rotate(0deg);
    } 
    20% { 
      top: -13%;
      left: 85%;
      transform: rotate(0deg);
    }
    25% {
      top: 0%;
      left: 81%;
      transform: rotate(90deg);
    }
    45% {
      top: 90%;
      left: 81%;
      transform: rotate(90deg);
    }
    50% {
      top: 94%;
      left: 78%;
      transform: rotate(180deg);
    }
    70% {
      top: 94%;
      left: -15%;
      transform: rotate(180deg);
    }
    75% {
      top: 90%;
      left: -25%;
      transform: rotate(270deg);
    }
    95% {
      top: -5%;
      left: -25%;
      transform: rotate(270deg);
    }
    100% {
      top: -13%;
      left: -10%;
      transform: rotate(360deg);
    } 
  }

  @keyframes md_moveAndRotate {
    0% {
      top: -10%;
      left: -10%;
      transform: rotate(0deg);
    } 
    20% { 
      top: -10%;
      left: 90%;
      transform: rotate(0deg);
    }
    25% {
      top: 0%;
      left: 87%;
      transform: rotate(90deg);
    }
    45% {
      top: 90%;
      left: 87%;
      transform: rotate(90deg);
    }
    50% {
      top: 96%;
      left: 78%;
      transform: rotate(180deg);
    }
    70% {
      top: 96%;
      left: -15%;
      transform: rotate(180deg);
    }
    75% {
      top: 90%;
      left: -18%;
      transform: rotate(270deg);
    }
    95% {
      top: -5%;
      left: -18%;
      transform: rotate(270deg);
    }
    100% {
      top: -10%;
      left: -10%;
      transform: rotate(360deg);
    } 
  }
  @keyframes lg_moveAndRotate {
    0% {
      top: -20%;
      left: -20%;
      transform: rotate(0deg);
    } 
    20% { 
      top: -20%;
      left: 75%;
      transform: rotate(0deg);
    }
    25% {
      top: 0%;
      left: 78%;
      transform: rotate(90deg);
    }
    45% {
      top: 90%;
      left: 78%;
      transform: rotate(90deg);
    }
    50% {
      top: 93%;
      left: 78%;
      transform: rotate(180deg);
    }
    70% {
      top: 93%;
      left: -25%;
      transform: rotate(180deg);
    }
    75% {
      top: 90%;
      left: -35%;
      transform: rotate(270deg);
    }
    95% {
      top: -10%;
      left: -35%;
      transform: rotate(270deg);
    }
    100% {
      top: -20%;
      left: -20%;
      transform: rotate(360deg);
    } 
  }

  @keyframes xl_moveAndRotate {
    0% {
      top: -5%;
      left: -8%;
      transform: rotate(0deg);
    } 
    20% { 
      top: -5%;
      left: 90%;
      transform: rotate(0deg);
    }
    25% {
      top: 0%;
      left: 95%;
      transform: rotate(90deg);
    }
    45% {
      top: 95%;
      left: 95%;
      transform: rotate(90deg);
    }
    50% {
      top: 98%;
      left: 90%;
      transform: rotate(180deg);
    }
    70% {
      top: 98%;
      left: -5%;
      transform: rotate(180deg);
    }
    75% {
      top: 90%;
      left: -8%;
      transform: rotate(270deg);
    }
    95% {
      top: 0%;
      left: -8%;
      transform: rotate(270deg);
    }
    100% {
      top: -5%;
      left: -8%;
      transform: rotate(360deg);
    } 
  }
  .trackcar {
    animation: default_moveAndRotate 15s infinite;
  }

  @media screen and (max-width: 450px) {
    .trackcar {
      animation: sm_moveAndRotate 15s infinite;
    }
  }

  @media screen and (max-width: 639px) {
    .trackcar {
      animation: md_moveAndRotate 15s infinite;
    }
  }

  @media screen and (max-width: 1080px) {
    .trackcar {
      animation: lg_moveAndRotate 15s infinite;
    }
  }

  @media screen and (min-width: 1880px) {
    .trackcar {
      animation: xl_moveAndRotate 15s infinite;
    }
  }


  @media (max-width: 380px) {
    .request-mechanic-text {
      margin-bottom: 3em;
    }

  
  }

  @media (max-width: 428px) {
    .app-is-live-div {
      margin: 0 auto;
    }
  
    .mechanic-pic-div {
      width: 100%;
    }

  }

@media(max-width: 520px) {
.input-button-div {
  justify-content: center;
  align-items: center;
  max-width: 250px;
}

}

@media(max-width: 640px) {
  .input-button-div {
    justify-content: center;
    align-items: center;
    max-width: 450px;
  }

  .app-is-live-div {
    margin: 0 auto;
  }

  .hero-big-text br{
    display: none;
    }
}

@media(max-width: 768px) {
  .app-is-live-div {
    margin-bottom: 1em;

  }

}

@media(max-width: 1075px) {
  nav .logo {
    gap: 10px;
  }

  .request-mechanic-text {
    width: 100%;
    max-width: none;
  }

  .input-button-div {
    width: 50vw;
    /* margin: auto; */
    margin: auto;
    margin-bottom: 2em; 
  }

    .hero-small-text {
      font-size: 15px;
      line-height: 25px;
    }
}

@media (min-width: 640px) and (max-width: 1075px) {
  .hero-img-div1, .hero-img-div2 {
    .mechanic-pic {
      width: 75%;
      height: 75%;
    }
  }
}



/* How it works Section */
.how-it-works-section {
  margin-top: 10em;
}

.how-it-works {
  color: #FFF;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 38.706px; /* 241.911% */
  letter-spacing: -0.32px;
}

.how-it-works-header {
  color: #FFF;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
}

.automobile-journey {
  color: #767575;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  }


  .timeline-container {
    display: flex;
    width: 100%;
    margin-top: 3em;
  }
  
  .timeline-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2;
  }
  
  .timeline-box {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid #22C466;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 0.5em;
  }
  
  .timeline-line {
    width: 235px;
    height: 1px;
    background: linear-gradient(270deg, #17A954 4.83%, rgba(217, 217, 217, 0.00) 102.73%);
  }


  .step-instructions-div {
    display: flex;
    flex-direction: row;
    gap: 6em;
  }

  .step-instructions {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .step-instructions-header {
    color: #FFF;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 38.706px; /* 215.032% */
    letter-spacing: -0.36px;
  }

  .step-instructions-text {
    color: #FFF;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }


  @media (max-width: 428px) {
    .timeline-container {
      display: flex;
      flex-direction: column;
    }
  }
  









/* Expert Guidance Section */
.expert-guidance-text {
  color: #fff;
  font-family: Coolvetica, sans-serif;
  font-weight: 550;
}


@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 30s linear infinite;
}


@media screen and (max-width: 420px) {
  .expert-guidance-text {
    text-align: center;
    font-size: 20px;
  }
}


@media (min-width:640px) and (max-width: 920px) {
  .expert-guidance-text {
    font-size: 45px;
  }
}

@media (min-width:421px) and (max-width: 640px) {
  #FAQs {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .FAQ-header {
    max-width: 100%;
    height: auto;
  }

  .max-h-96 {
    max-height: none;
  }
  
  .expert-guidance-text {
    font-size: 30px;
  }
}

@media (max-width: 1075px) {
  .expert-guidance-text {
    margin-bottom: 2em;
  }
}



/* Features Section */

.Features-header-div {
  border: 1px solid #75757580;
  background-color: #191919;
  border-radius: 35px;
}

.AI-Powered {
  color: #fff;
  font-family: Coolvetica, sans-serif;
  outline: none;
  border: none;
  line-height: 1;
}

.Advanced-AI-algorithms {
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 15px;
  color: #AFB7C5;
}

.discover-grids {
  flex-direction: row;
}

.discover-grid-box {
  border: 1px solid #75757580;
  background-color: #191919;
  border-radius: 35px;
}

.features-sub-heading {
  margin-top: 0.5em;
  color: #fff;
  font-family: Coolvetica, sans-serif;
  font-size: 24px; /* Decrease font size for better fit */
  font-weight: 550;
  white-space: nowrap;
  text-align: left;
}

.features-sub-text {
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  font-size: 12px; /* Decrease font size for better fit */
  color: #AFB7C5;
  margin-top: 0.5em; /* Adjust margin for better spacing */
  text-align: left;
}



@media (max-width: 420px) {
  .AI-Powered {
    text-align: left;
    margin: 1em auto;
    font-size: 20px;
  }

  .Advanced-AI-algorithms {
    text-align: left;
    margin: 0 auto;
    font-size: 12px;
  }
}

@media (max-width: 640px) {
  .Features-header-div {
    height: auto;
  }

  .featuresText {
    width: 100%;
    height: auto;
    margin: 2em auto;
  }

  .cultureNames {
    width: 100%;
    height: 15em;
    margin: 0 auto;
  }
}

@media (max-width: 768px){
  .Features-header-div {
    height: auto;
  }

  .featuresText {
    margin: 0 auto;
  }

  .cultureNames {
    margin: 0 auto;
  }

  .discover-grids {
    flex-direction: column;
  }
}

@media (min-width: 421px) and (max-width: 639px) {
  .AI-Powered {
    text-align: left;
    margin: 1.5em auto;
    font-size: 35px;
  }

  .Advanced-AI-algorithms {
    text-align: left;
    margin: 0 auto;
    font-size: 12px;
  }
}
 
@media (min-width: 640px) and (max-width: 767px) {
  .AI-Powered {
    text-align: center;
    margin: 1em auto;
    font-size: 35px;
  }

  .Advanced-AI-algorithms {
    text-align: center;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1275px) {
  .Features-header-div {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
  }

  .featuresText {
    width: 100%;
    max-width: 300px; /* Adjust the maximum width of the first image */
    margin: 2em auto; /* Add some spacing */
  }

  .AI-Powered {
    text-align: center;
    margin: 1em auto;
  }

  .Advanced-AI-algorithms {
    text-align: center;
    margin: 0 auto;
  }

  .cultureNames {
    width: 100%;
    max-width: 400px;
    margin: 2em auto; /* Add some spacing */
  }
}


/* Support Section */
.experience-seamless-car {
  margin-top: 1em;
  color: #fff;
  font-family: Coolvetica, sans-serif;
}

.phone-image {
  margin-left: 25%;
}


@media (max-width: 420px) {
  .experience-seamless-car {
    font-size: 30px;
  }

  .phone-image {
    margin: 0 auto;
  }
}


@media (min-width: 420px) and (max-width: 560px) {
  .phone-image {
    margin: 0 auto;
  }
}


@media (max-width: 767px) {
  .support-section {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .support-div1 {
    text-align: center;
    align-items: center;
  }

  .support-div2 {
    width: 100%;
  }
}




@media (max-width: 1024px) {
  .experience-seamless-car {
    font-size: 25px;
      .phone-image {
    margin: 0 auto;
  }
  }
}


/* FAQ Section */
.frequently-asked-questions {
  margin-top: 1em;
  color: #fff;
  font-family: Coolvetica, sans-serif;
  font-size: 70px;
  font-weight: 550;
  text-align: center;
  line-height: 1.3;
}

@media (max-width: 420px) {
  .frequently-asked-questions {
    font-size: 15px;
  }
}

@media (min-width:421px) and (max-width: 640px) {
  .frequently-asked-questions {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .frequently-asked-questions {
    font-size: 45px;
  }
}

.footer-text {
font-family: sans-serif;
font-size: 15px;
font-weight: 400;
text-align: left;
color: #E1D9D1;
}

.email-link {
  text-decoration: none;
  color: #fff;
  font-size: 13px;
}




















/* Dashboard styles begin Here */

/* Sidebar */

.sidebar {
  background-color: #000;
  color: #ffffff;
  width: 100%;
  height: 100vh;
  padding: 45px;
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease;

}

.sidebar-non {
  display: none;
}

.logor {
  margin-bottom: 20px;
}

.sidebar-text {
  /* font-size: 14px; */
  font-weight: 400;
  /* line-height: 18px; */
  letter-spacing: 0em;
  text-align: left;
}

.sidebar-links {
  list-style: none;
  padding: 0;
}

.sidebar-links li {
  display: flex;
  cursor: pointer;
}

/* .sidebar-links li svg {
  margin-right: 10px;
} */

.sublink {
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout {
  margin-top: auto;
  cursor: pointer;
}

/* Show mobile sidebar when .show-mobile-menu class is applied */
/* .sidebar.sidebar-open {
  width: 100%;
} */

/* Mobile Hamburger Menu */
.mobile-hamburger {
  display: none;
  justify-content: flex-end;
  padding: 20px;
  cursor: pointer;
}

.hamburger-icon {
  font-size: 24px;
}

#landscape{
  display: none;
}

/* Show mobile hamburger menu on smaller screens */
@media (max-width: 968px) {
  .sidebar.sidebar-close {
    display: none;
  }

  .sidebar.sidebar-open {
    display: flex;
    align-items: center;
  }

  .mobile-hamburger {
    display: flex;
  }

  .hamburger-icon {
    font-size: 24px;
    color: #000;
  }
}

@media (orientation:landscape) {
  #mobile{
    display: none;
  }
  #landscape{
    display: flex;
  }
  .sidebar{
    width: 20%;
  }
  .sidebar.sidebar-open {
    width: 20%;
    display: flex;
    align-items: center;
  }
  .sidebar.sidebar-close{
    display: flex;
  }
}
/* Close Sidebar Button */
.close-sidebar-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  display: none;
}

@media (max-width: 1024px) {
.sidebar{
  padding: 45px 20px;

}
}

@media (max-width: 980px) {
  .close-sidebar-button {
    display: block;
  }
}

.close-icon {
  font-size: 24px;
  color: white; /* You can adjust the color as needed */
  font-weight: bold;
}

/* Show close icon button on smaller screens */
@media (max-width: 968px) {
  .close-sidebar-button {
    display: block;
  }
}



/* Top Header Component */
.top-header {
  width: 100%;
  background-color: #fff;
  height: 5em;
  border-bottom-width:2px;
  border-bottom-color: #f5f6f9;
  border-bottom-style: solid;
}

.profile-name {
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: #1B293A;
}

.profile-role {
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color:#7A8699;
}


@media (max-width: 420px) {
  .top-header {
    justify-content: space-between; /* Adjust alignment for smaller screens */
    padding: 0 10px; /* Adjust padding for smaller screens */
    height: auto; /* Allow height to adjust to content */
  }

  .divider {
    display: none; /* Hide the divider on smaller screens */
  }

  .notification-icon {
    margin-left: 5em;
  }

  .notification-badge {
    width: 15px;
    height: 15px;
    font-size: 0.6rem;
    line-height: 1rem;
  }

  .profile {
    flex-direction: column; /* Stack profile info vertically on smaller screens */
    align-items: center; /* Center align profile info */
  }

  .profile-name {
    font-size: 14px;
    text-align: center; /* Center align profile name */
  }

  .profile-role {
    font-size: 10px;
    text-align: center; /* Center align profile role */
  }

}




/* Dashboard styles */
.time-frame-button1 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.time-frame-plus-icon {
  border-right: none;
}

.shadow-container:before {
  bottom: -6px; /* Adjust the distance of the shadow from the chart */
  left: 0;
  width: 100%;
  height: 12px; /* Adjust the height of the shadow */
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); /* Adjust color variants */
  filter: blur(6px); /* Adjust the blur radius to control shadow's softness */
  z-index: -1;
}


.dashboard-chart {
  width: 62.5%;
}


.transaction-volume {
  width: 37.5%;
}

.credit-debit-div {
  border-radius: 25px;
}


.dashboard-container {
  position: relative;
}

.hamburger-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  display: none;
}

@media (max-width: 980px) {
  .hamburger-icon {
    display: block;
  }
}

@media (max-width: 420px) {
  .dashboard-text {
    font-size: 15px;
  }

  .dashboard-stats {
    display: block; /* Change from grid to block layout */
  }

  .dashboard-stat-container {
    width: 100%; /* Take up the full width */
    margin-right: 0; /* Remove horizontal margin */
    margin-bottom: 20px; /* Add vertical margin for spacing */
  }

  /* Reset icon and text alignment */
  .dashboard-stat-icon,
  .dashboard-stat-icon svg {
    margin: 0 auto;
  }

  .dashboard-stat-icon {
    width: 60px;
    height: 60px;
  }

  /* Adjust text alignment and spacing */
  .dashboard-stat-container h3,
  .dashboard-stat-container p {
    text-align: center;
    margin-top: 10px;
  }
}



/* Add a media query for screens smaller than 768px */
@media (max-width: 768px) {
  .dashboard-chart-container {
    flex-direction: column; /* Stack items on top of each other */
    align-items: center; /* Center items horizontally */
    gap: 20px; /* Add vertical spacing between items */
    margin: 0 8px; /* Adjust horizontal margins */
  }

  .dashboard-chart {
    width: 100%; /* Take up full width */
    margin: 0; /* Remove margin */
  }

  .Time-frame-divs {
    flex-direction: column; /* Stack time frame buttons on top of each other */
    align-items: center; /* Center items horizontally */
  }

  .time-frame-buttons,
  .time-frame-plus-icon {
    width: 100%; /* Take up full width */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .transaction-volume {
    width: 100%; /* Take up full width */
    height: auto; /* Adjust height to fit content */
    padding: 20px; /* Add padding for spacing */
  }

  .transaction-volume h3,
  .transaction-volume p {
    text-align: center; /* Center text */
  }

  .transaction-history-heading-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .transaction-history-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .transaction-history-heading {
    text-align: center;
  }

  .credit-debit-div {
    width: 100%;
  }

  .calandar-date-div {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    padding: 10px;
    margin-bottom: 1em;
  }

  .transaction-amount-div {
    margin: 1em auto;
    font-size: 18px;
  }

  .data-date, .data-user {
    font-size: 18px;
  }


}



/* Profile Stylings */
.user-icon-container {
  background-color: #245444;
}

.personal-info-mini-div {
  display: flex;
  flex-direction: row;
}

.gifImage {
  width: '1170px';
  height: '532px';
}

.login-header {
  font-weight: bold;
  font-size: 20px;
}

#login-button {
  background: #000;
  color: #000;
}
 

.tidiochat {
  background-color: #fff;
  color: #fff;
}

.try-now-link {
  text-decoration: none;
  color: #000;
}

#FAQs{
  scrollbar-width: 1rem;
}

#FAQs::-webkit-scrollbar{
  background-color: #000000;
  width: 0.4rem;
}
#FAQs::-webkit-scrollbar-thumb{
  background-color: #17A954;
  border-radius: 0.5rem;
}
#FAQs::-moz-scrollbar{
  background-color: #000000;
  width: 0.4rem;
}
#FAQs::-moz-scrollbar-thumb{
  background-color: #17A954;
  border-radius: 0.5rem;
}
#FAQs::-ms-scrollbar{
  background-color: #000000;
  width: 0.4rem;
}
#FAQs::-ms-scrollbar-thumb{
  background-color: #17A954;
  border-radius: 0.5rem;
}

#FAQs::-o-scrollbar{
  background-color: #000000;
  width: 0.4rem;
}
#FAQs::-o-scrollbar-thumb{
  background-color: #17A954;
  border-radius: 0.5rem;
}


#car{
  animation: drive 10s infinite;
}
@keyframes drive {
  0%{
    transform: translate(0%,-50%);
    top: 0%;
    rotate: 0deg;
    left:0%
  }
  18%{
    transform: translate(-50%,-50%);
    top: 0%;
    rotate: 0deg;
    left:92%
  }
  25%{
    transform: translate(25%,100%);
    top: 0%;
    rotate: 90deg;
    left:100%
  }
  43%{
    transform: translate(5%,50%);
    top: 100%;
    rotate: 90deg;
    left:92%
  }
  50%{
    transform: translate(40%,75%);
    top: 100%;
    rotate: 180deg;
    left:100%
  }
  68%{
    transform: translate(50%,50%);
    top: 100%;
    rotate: 180deg;
    left:0%
  }

  75%{
    transform: translate(50%,-100%);
    top: 100%;
    rotate: 270deg;
    left:0%
}
  93%{
    transform: translate(0%,-100%);
    top: 4%;
    rotate: 270deg;
    left:0%
  }
    100%{
      transform: translate(0%,-50%);
      top: 0%;
      rotate: 360deg;
      left:0%
  }
}
#backButtonA{
  font-family: "Coolvetica";
}

.ErrorContainer{
  overflow-x: hidden;
}

@media (max-width: 768px) {
  th.id-column,
  td.id-column {
    display: none;
  }
}


/* Screens between 640px and 1075px */
@media (min-width: 640px) and (max-width: 1075px) {
  .app-store-text {
    font-size: 0.8rem; /* Adjust the font size as needed */
  }
}


/* hover class for the border of the Profile type page */
.hover-green{
  border: 1px solid #EBEBEB;
  border-radius: 3px;
}
.hover-green:active{
  border-color: #1EB85F;
  border-width: 1px;
  border-style: solid;
}

/* modal overlay */
.modal-background{
  background-color: rgba(0,0,0,0.05);
}


/* .MuiFormControl-root.MuiTextField-root.css-7rtjrq-MuiFormControl-root-MuiTextField-root>{

} */

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.Mui-readOnly.MuiInputBase-readOnly.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
font-family: "SharpSans";
}

.MuiDialogContent-root.css-9yjdhh-MuiDialogContent-root{
  font-family: "SharpSans";

}
.MuiDialogContent-root.css-9yjdhh-MuiDialogContent-root *{
  font-family: "SharpSans";

}
.MuiTypography-root.MuiTypography-overline.css-1hbyad5-MuiTypography-root{
  font-family: "SharpSans";
}

.MuiClock-clock.css-4f0ona-MuiClock-clock{
  background-color: rgba(0, 0, 0, 0);
}

.MuiDialogContent-root.css-9yjdhh-MuiDialogContent-root{
background-color: rgb(220 252 231);
border-radius: 2rem;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  border-radius: 2rem;
}

.MuiTypography-root.MuiTypography-overline.css-1hbyad5-MuiTypography-root{

  color: black ;
  text-transform: none;
  font-weight: 600;

}

.MuiTimePickerToolbar-hourMinuteLabel.css-trxt40-MuiTimePickerToolbar-hourMinuteLabel{
  align-items: center;
}

.MuiTimePickerToolbar-hourMinuteLabel.css-trxt40-MuiTimePickerToolbar-hourMinuteLabel button{
  padding: 1rem;
  background-color: #17A954;
}
.MuiTimePickerToolbar-hourMinuteLabel.css-trxt40-MuiTimePickerToolbar-hourMinuteLabel button ~ button{
  padding: 1rem;
  background-color: white;
}
.MuiTimePickerToolbar-hourMinuteLabel.css-trxt40-MuiTimePickerToolbar-hourMinuteLabel span{
  font-weight: 900;
  color: whitesmoke;
}
.MuiTimePickerToolbar-hourMinuteLabel.css-trxt40-MuiTimePickerToolbar-hourMinuteLabel button ~ button span{
  font-weight: 900;
  color: black;
}
.MuiTimePickerToolbar-hourMinuteLabel.css-trxt40-MuiTimePickerToolbar-hourMinuteLabel button ~ span{
  font-weight: 900;
  color: black;
}

.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar.css-knqc4i-MuiDialogActions-root button{
color: green;
}

.MuiTimePickerToolbar-ampmSelection.css-ihhycx-MuiTimePickerToolbar-amPmSelection button span{
font-weight: 900;
}

.MuiTimePickerToolbar-ampmSelection.css-ihhycx-MuiTimePickerToolbar-amPmSelection{
  border: solid 0.08rem black;
  border-radius: 0.5rem;
}
.MuiTimePickerToolbar-ampmSelection.css-ihhycx-MuiTimePickerToolbar-amPmSelection button{
  padding: 0.5rem 1rem;
  height: 50%;
  width: 100%;
}
.MuiTimePickerToolbar-ampmSelection.css-ihhycx-MuiTimePickerToolbar-amPmSelection button ~ button{

  border-top: #000 0.08rem solid;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  background-color: #6BFDCB;
}

.MuiClock-pin.css-umzx0k-MuiClock-pin,.MuiClockPointer-root.css-d0vs79-MuiClockPointer-root,.MuiClockPointer-thumb.css-f53ilk-MuiClockPointer-thumb{
  background-color: #027A48;
  border-color: #027A48;
}

.MuiPickersArrowSwitcher-root.MuiTimeClock-arrowSwitcher.css-2x8kvt-MuiPickersArrowSwitcher-root-MuiTimeClock-arrowSwitcher{
  display: none;

}

/* .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.MuiPickersToolbar-root css-13u1oz-MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root{
background-color: #17A954;
color: white;
} */
/* styles for the remember me check box */
/* success gradient logo on the success verification page */

.success-gradient{
    
  background-image: linear-gradient(to right, #C5FFDD, #4EE68E);
  
}
.tick-gradient {
  opacity: 0;
  max-height: 0;
  animation: gradientActivate 0.4s ease forwards;
}

@keyframes gradientActivate {
  0% {
      opacity: 0;
      max-height: 0;
      max-width:0
  }
  100% {
      opacity: 1;
      max-height: 1000px;
      max-width: 1000px;
  }
}


#active > .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,#away > .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked{
  color: #17A954;
}

#active >p,#away>p{
  color:black;
}

button.css-mvepu3-MuiButtonBase-root-MuiButton-root:hover{
  background-color: white;
}

div > div.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  font-family: "SharpSans";
}


div.reactVideoPlayer{
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.reactVideoPlayer > video{
  min-height: 100%;
  height:100%;
  width: auto;
  min-width: auto;
  max-width: 1000%;
  border-radius: 0.8rem;
}

span .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
  color:#1EB85F
}

span .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background-color: #4EE68E;
}

/* modal overlay */
.modal-background{
  background-color: rgba(0,0,0,0.05);
}